/* src/App.css */
#root {
  height: 100%;
}

/* Стиль для элемента с эффектом загрузки */
.skeleton {
  position: relative;
  background-color: #2e3235;
  /* Цвет фона для скелетона */
  border-radius: 4px;
  /* Закругление углов */
  overflow: hidden;
  /* Прячем содержимое за пределами блока */
}

/* Эффект загрузки с анимацией */
.skeleton::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(130deg, rgba(46, 50, 53, 0.1), rgba(123, 127, 131, 0.2), rgba(46, 50, 53, 0.1));
  animation: loading 2s infinite;
  /* Задаем анимацию, уменьшив время для более быстрой анимации */
}

/* Анимация движения */
@keyframes loading {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(200%);
    /* Изменение с 200% на 100% для более плавного эффекта */
  }
}

.ͼ1.cm-focused {
  outline: none !important;
}

.ͼ1 .cm-cursor,
.ͼ1 .cm-dropCursor {
  border-left: 1.2px solid #ffffff;
}

*::-webkit-scrollbar,
ul [role="tree"]::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  border-radius: 10px !important;
  background-color: #959FA7 !important;
}

*::-webkit-scrollbar-thumb,
ul [role="tree"]::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: #727A80 !important;
}

*::-webkit-scrollbar-track,
ul [role="tree"]::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2) !important; */
  border-radius: 10px !important;
  background-color: #959FA7 !important;
}

.Mui-selected:not(.MuiTab-root),
.cm-activeLine,
.cm-activeLineGutter {
  background-color: #585E62 !important;
}

.cm-activeLineGutter {
  color: #2e3235 !important;
}

.Mui-selected {
  border-radius: 5px;
}

.cm-activeLine {
  border-radius: 0 5px 5px 0;
}

.cm-lineNumbers .cm-activeLineGutter {
  border-radius: 5px 0 0 5px;
}

.MuiTreeItem-content {
  gap: 4px !important;
}

.cm-theme {
  height: 100%;
}


.cm-editor {
  height: 100%;
  /* min-height: 100px; */
  /* background-color: #2e3235;
  color: #C5C5C5; */
}

.ͼ1 .cm-lineNumbers .cm-gutterElement {
  text-align: left;
}

.ͼ27 .cm-gutters {
  border-right: 1px solid #A8A8A8;
}

.cm-line {
  padding-left: 7px;
}